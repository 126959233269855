body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
  width: 450px;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 350px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 350px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 110px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #fa8c16;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Banner5_0.lg2ero1vdv-editor_css {
  color: #fa8c16;
  background-color: #ffffff;
}
#Feature3_0.lg3gdlbhgh9-editor_css {
  min-height: 464px;
}
#Nav3_0 .home-page > .lg3fyjsznzs-editor_css {
  width: 80px;
}
#Banner5_0 .banner5-title-wrapper > .banner5-button-wrapper > .lg3fx8yhnx-editor_css {
  font-weight: 500;
  background-color: rgba(250, 140, 22, 0.72);
  background-image: linear-gradient(to right bottom, rgba(250, 140, 22, 0.7) 0%, rgba(250, 140, 22, 0.78) 60.76925385%);
  box-shadow: 0px 8px 24px #f0b16d;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .lg3g3ufy0x8-editor_css {
  font-size: 16px;
}
#Nav3_0 .ant-menu-submenu-title > .header3-item-block > .lg3g41tyxz-editor_css {
  font-size: 16px;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .lg3g4ehb97a-editor_css {
  font-size: 16px;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .lg3g4pleb57-editor_css {
  font-size: 16px;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .lg3g6oi3e3u-editor_css {
  font-size: 16px;
}
#Banner5_0 .home-page > .lg2er5kwbep-editor_css {
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  transition: none 0s ease 0s;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .lg3fubqb6a-editor_css {
  color: rgba(250, 140, 22, 0.99);
}

